.customize-main {
    display: block !important;
    height: 100%;
    position: relative;
    background: #f0f5fb;
    overflow: hidden;
    .card-details {
        height: calc(100% - 72px);
        overflow: scroll;
        .card {
            margin-top: 2px;
            margin: 8px;
            display: flex;
            background: #fff;
            padding: 8px;
            box-shadow: 0 1px 1px rgba(0,0,0,.2);
            color: black;
            border: 2px solid transparent;
            .left-card{
                display: flex;
                div{
                    padding:2px 8px;
                }
                .hotel-img{
                    img{
                        width: 100px;
                        height: 80px;
                    }
                }
                .hotel-info {
                    p {
                        margin-bottom: 4px;
                    }
                }
            }
            .right-card{
                padding: 2px 8px;
                font-size: 14px;
            }
            // &.active {
            //     // border: 2px solid green;
            // }
        }
    }
    .am-icon{
        width: 22px;
        height: 22px;
    }
}
