.exp_inner {
    position: relative;
    figure {
        overflow: hidden;
        height: 400px;
        position: relative;
        margin:0px;
        img {
            position: absolute;
            top: -9999px;
            right: -9999px;
            bottom: -9999px;
            left: -9999px;
            margin: auto;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            max-width: 100%;
            border: 0;
        }
    }
    figcaption {
        position: absolute;
        z-index: 9;
        top: 50%;
        color: #fff;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        text-align: center;
        width: 100%;
        font-size: 13px;
        padding: 0 15px;
        font-weight: bolder;
        h3 {
            color: white;
            display: inline-block;
            text-transform: uppercase;
            font-size: 24px;
            margin-bottom: 32px;
        }
        span {
            font-size: 16px;
            color: white;
            font-weight: normal;
            margin: 0px 14px !important;
            display:block;
        }
    }
    a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #0000006e
    }
}

.grid-box {
    .col-6:nth-child(1n) {
        padding-left:0px !important;
    }
    .col-6:nth-child(2n) {
        padding-right:0px !important;
    }
    h3 {
        font-size: 18px !important;
    }
}