.tab-bar{
    overflow: scroll;
    flex-wrap: nowrap;
    position: relative;
    top: 0px;
    width: 100%;
    height: 70px;
    background: white;
    justify-content: space-between;
    padding: 4px 8px;
    align-items: center;
    border-bottom: 1px solid rgb(221, 221, 221);
    transition-duration: 0.2s;
    z-index: 100000;
    .tab-item {
        position: relative;
        span {
            font-size: 14px;
        }
        .icon-container {
            border: 1px solid;
            border-radius: 16px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            position: relative;
            align-items: center;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0px;
            bottom: -8px;
            height: 4px;
            background-color: transparent;
            width: 100%;
            transition: 0.2s all;
        }
        &.active {
            color: #1a8cd2;
            &::before {
                background-color: #1a8cd2;
            }
        }
    }
    span {
        cursor: pointer;
        &.active {
            color: #1a8cd2;
        }
    }
}
