$yellow: #fed200;
$orange: #e1982d;
$dgrey:#4c627a;
$lgrey:#d9e1ec;
$black:#000000;
$white:#ffffff;
$greytext:#7a90a7; // (footer, leftside panel, details page)
$dgreen:#46912b; // (price at accordion ) 
$lgreen:#edf9e5; // (calendar event)
$blue:#1a8cd2;  // (all btns for ex.)
$dblue:#3254cc;
$lblack:#00000099;
$footerbg:#e4effc;
$footerborder:#cadfec;
$calendarBorder:#e3dfdf;
$calendarBg:#fafbfc;
$calendarHeader:#e6e6e6;
$loaderBg:#0000004f;
$accordionBg: #f0f5fb;

$sizeVar: 8, 10, 12, 14, 15, 16, 17, 18, 20, 22, 24;
@each $size in $sizeVar {
  .fs-#{$size} {
    font-size: ($size / 12) + rem;
  }
}