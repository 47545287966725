// google fonts
@font-face {
	font-family: 'openSans';
	src: url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
}

@font-face {
  font-family: 'museo-500';
  src: url("assets/fonts/Museo500-Regular.otf");
}

@font-face {
  font-family: 'CastleT';
  src: url("assets/fonts/CastleT.ttf");
}

@import './variable.scss';
@import './theme.scss';

body {
  	margin: 0px;
	font-size: 14px !important;
}

.d-inline-grid {
	display: inline-grid !important;
}

p {
	color: black;
}

.border-bottom {
	border-bottom: 1px solid #909396!important
}

.yaana-body {
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 18px;
	overflow: hidden;
	.yaana-loader {
		background: #bfc3c742;
		width: 100%;
		height: 100vh;
		position: absolute;
		overflow: hidden;
		z-index: 10000;
		top: -60px;
		left: 0px;
		div {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.yaana-container {
		position: relative;
		height: calc(100% - 132px);
		// height: calc(100% - 60px);
		display: block;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
		overflow-y: scroll;
		.sticky-container {
			height: 100%;
		}
		> section {
			padding: 8px;
			width: calc(100%);
			height: 100%;
			// max-height: calc(100% - 72px);
			overflow: hidden;
			overflow-y: scroll;
		}
		.sourceInfo {
			span {
				display: inline-block;
				// margin-right: 8px;
			}
			input[type='text'] {
				border: 0px;
				border-bottom: 1px solid;
				display: inline-block;
				// width: calc(100% - 63px);
				border-radius: 0px;
				box-shadow: none;
				font-size: 16px;
				color: black;
				&:focus {
					border: 0px;
					border-bottom: 1px solid $blue;
          			outline: none;
				}
			}
		}
		.carousel {
			position: relative;
			.carouselImg {
				width: 100% !important;
			}
			.carouselBtn {
				position: absolute;
				background: $orange;
				color: white;
				left: 50%;
				transform: translate(-50%, -50%);
				top: 50%;
				width: 150px;
				outline: none;
				border: none;
				font-size: 14px;
				padding: 8px;
				a {
					text-decoration: none;
					color: white;
				}
      		}
			.awssld--organic-arrows {
				.awssld__bullets {
					button {
						background: $blue !important;
						margin: 8px !important;
						opacity: 0.4 ;
						width: 10px;
						height: 10px;
					}
				}
				.awssld__bullets--active {
					transform: scale(2) !important;
					opacity: 1 !important;
				}
				.awssld__controls {
					display: none !important;
					// visibility: visible !important;
				}
			}
		}
	}

	.sub-menu {
		button:focus {
			outline: none;
		}
	}
}

.nav-bar {
	overflow: hidden;
    position: fixed;
    width: calc(100%);
    justify-content: center;
    background: #f1f1f1;
    height: 72px;
    z-index: 100000 !important;
    display: flex;
    padding: 8px;
	bottom: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	button {
		border: 0px solid !important;
		box-shadow: none !important;
		outline: none !important;
	}
	button.bg-blue {
		background: $blue !important;
	}
	button.bg-warning {
		background-color: $orange !important;
		&:focus {
			background-color: $orange !important;
		}
	}
	a, button, span {
		height: 40px;
		font-weight: bold;
		// &:focus {
		// 	background-color: #ffc107 !important;
		// }
	}
}

.invalid-input {
	border: 1px solid red;
}

.text-link {
  color: $blue;
}

.white-text {
  color: white !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__portal {
	> div {
		width: 90%;
		.react-datepicker {
			width: 100%;
			.react-datepicker__month-container {
				width: 100%;
			}
			.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
				width: 3rem !important;
				font-size: 14px !important;
			}
		}
	}
}

.react-datepicker__input-container {
	input {
		text-align: left;
		border: 0px;
		&:focus {
		outline: none;
		}
	}
}

.btn {
	background: $blue;
	color:$white;
	font-size: 18px;
	padding: 8px 16px;
	&.btn-link {
		width: 100%;
		text-align: left;
		width: 100%;
		letter-spacing: 1px;
		margin: 0;
		border-radius: 2px;
		background: $blue;
		color:$white !important;
		font-weight: bold;
		// opacity: 1;
		&:hover,
		&:active,
		&.active,
		&:focus {
			text-decoration: none;
			background-color: $blue !important;
			color:$white !important;
		}
	}
	a {
		text-decoration: none;
		color: white;
	}
	&.btn-blue {
		padding: 8px 32px;
		border-radius: 4px;
		letter-spacing: 1px;
		cursor: pointer;
		margin: 0px;
		outline: none;
		box-shadow: none !important;
		font-weight: bold;
		background: $blue !important;
		background-color: $blue !important;
		color: $white;
	}
	&.btn-green {
		@extend .btn-blue;
		background: $dgreen !important;
		background-color: $dgreen !important;
	}
	&.btn-yellow {
		@extend .btn-blue;
		background: $yellow !important;
		background-color: $yellow !important;
	}
	&.btn-orange {
		@extend .btn-blue;
		background: $orange !important;
		background-color: $orange !important;
	}
}

.btn-container {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 50%;
	width: 100%;
	outline: none;
	border: none;
	font-size: 14px;
	padding: 8px;
	text-align: center;
}

.calendar__list {
	display: block !important;
	.calendar__item {
		display: block !important;
	}
}

.picker-input {
	width: auto !important;
	.picker-input__text {
		border: none !important;
		text-align: center !important;
	}
}

.Dropdown-root {
	width: 95% !important;
	left: 0% !important;
	.Dropdown-control {
		border: none !important;
		.Dropdown-arrow {
			top: 50% !important
		}
	}
	.Dropdown-menu {
		text-align: left !important;
	}
}

.form-control {
	padding: 16px !important;
	height: auto !important;
	&:focus {
		box-shadow: none;
    	border-color: #ced4da !important;
	}
	&:disabled, &[readonly]{
		background-color: transparent !important;
	}
}

.awssld__bullets {
	button {
		background: #ced4da !important;
	}
	.awssld__bullets--active {
		background: $blue !important;
	}
}
.awssld__controls {
	display: none;
}

.css-2b097c-container {
	.css-1hb7zxy-IndicatorsContainer {
		display: none !important;
	}
	.css-yk16xz-control {
		border: 0px !important;
	}
	&:focus {
		outline: none !important;
	}
	.css-26l3qy-menu {
		position: absolute;
		z-index: 10000001;
	}
}

@media (max-width: 520px) {
	// .awssld__controls {
	// 	display: none;
	// }
}

iframe {
	width: 100% !important;
}

.section-detail {
	.awssld__bullets {
		visibility: visible !important;
	}
	.awssld__controls {
		visibility: visible !important;
		display: block !important;
	}
}

.dest-text {
	text-decoration: underline;
	font-weight: normal;
	cursor: pointer;
	color: blue;
}
.bg-warning {
	background-color: $orange !important;
}

.section-detail {
	background: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100000;
}

.video-box {
	> div {
		width: 100%;
		height: 100%;
		iframe {
			width: 100%;
			height: 100%;
		}
	}
}

.cheeseburger-menu-overlay{
	z-index: 1000000 !important;
}
.cheeseburger-menu-outer {
	z-index: 1000001 !important;
}

.carousel {
	.slide {
		background: transparent !important;
	}
	.thumbs-wrapper {
		display: none;
	}
	.carousel-status {
		display: none;
	}
	.control-dots {
		.dot {
			background: rgba(0, 0, 0, 0.65) !important;
		}
	}
}

.wrap_destination {
	.wrap_destination_inner {
		position: relative;
		figure {
			overflow: hidden;
			position: relative;
			min-height: 175px;
			max-height: 175px;
			margin: 0;
		}
		figcaption {
			padding: 0 10px;
			position: absolute;
			bottom: 20px;
			color: #fff;
			z-index: 9;
			h3 {
				color: #fff;
			}
			p {
				color: #fff;
			}
		}
		a, span {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			cursor: pointer;
			z-index: 10;
		}
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: #0000006e;
		}
	}
	img {
		position: absolute;
		top: -9999px;
		right: -9999px;
		bottom: -9999px;
		left: -9999px;
		margin: auto;
		min-width: 100%;
		min-height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		&:hover {
			transform: scale(1.1);
			transition: .5s all;
		}
	}
}


.bg-slide {
	.exp_inner {
		position: relative;
		figcaption {

		}
	}
	p {
		color: white;
	}
	&::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: #0000006e;
	}
}