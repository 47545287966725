.heading-container {
    margin-bottom: '24px !important';
    .heading {
        font-size: 32px;
        text-align: center;
        font-weight: bold;
    }
}

.destination-container {
    input {
        font-size: 16px;
        border: 0px;
        color: #171616;
    }
}


.dest-info {
    position: relative;
    color: white;
    font-weight: normal;
    p {
        color: white;
    }
    .dest-text {
        color: white;
        text-decoration: underline;
        font-weight: normal;
        cursor: pointer;
    }
    input {
        color: white;
        background: transparent;
        &::placeholder {
            color: white;
            opacity: 1; /* Firefox */
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: white;
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: white;
        }
    }
}

.bgOverlay {
    display: block !important;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000099;
    z-index: 1;
}

.bgImg {
    background-image: url(https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png);
    background-repeat: no-repeat;
    background-size: cover;
}

