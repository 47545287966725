
.review-container {
    .ratting{
        .checked {
            color: #fed200;
        }
    } 
    .rting{
        white-space: nowrap;
        display: block;
    }
    .author-name {
        a {
            text-decoration: none;
        }
    }
    .profile {
        img {
            width: 40px;
            height: 40px;
        }
    }
    h3 {
        font-weight: 700;
    }
}

.similar-container {
    .similar-grid {
        display: block;
        .card {
            position: relative;
            display: inline-block;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid rgba(0,0,0,.125);
            border-radius: .25rem;
            width: calc(100% / 2 - 4px);
            margin: 4px 0px;
            margin-right: 8px;  
            img {
                width: 100%;
                height: 90px;
            }
            button {
                padding: 4px 16px;
            }
        }
        .card:nth-child(2n) {
            margin-right: 0px;
        }
    }
}

.awssld__bullets {
    bottom: -20px;
    button {
        width: 10px;
        height: 10px;
    }
}