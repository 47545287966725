@import '../../variable.scss';
.sub-menu {
    .MuiBottomNavigation-root {
        justify-content: space-between !important;
        height: auto;
    }
    button {
        cursor: pointer;
        // font-size: 24px !important;
        // padding-top: 16px !important;
        &:focus {
            outline: none !important;
        }
        .MuiBottomNavigationAction-label {
            opacity: 1 !important;
            font-size: 16px !important;
        }
    }
    .MuiBottomNavigationAction-root.Mui-selected {
        border-bottom: 2px solid #1a8cd2 !important;
        margin-bottom: 1px !important;
        padding-top: 16px;
        font-weight: bolder;
        color: #1a8cd2;
    }
}