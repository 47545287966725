.menu {
  font-family: sans-serif;
  .logo-section {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  .menu-item {
    a {
      display: block;
      padding: .7rem 15px;
      color: inherit;
      text-decoration: none;
      &:hover {
        color: inherit;
        text-decoration: none;
        background-color: #eee;
      }
      &:focus {
        text-decoration: none;
      }
      &:visited {
        color: inherit;
      }
    }
    .active-menu-item {
      background-color: #e8e8f9;
    }
  }
  .footer-part {
    background-color: #e4effc;
    color: #7a90a7;
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 200px;
    padding: 8px;
    h3 {
      color: #4c627a;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0px;
      font-size: 14px;
    }
    ul {
      list-style: none;
      padding: 16px 0 0;
      li {
        padding-top: 8px;
        outline: 0;
        cursor: pointer;
        font-size: 14px;
        a {
          color: #7a90a7 !important;
        }
      }
    }
  }
}
