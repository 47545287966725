// Theme specific
@mixin applyTheme($primary, $secondary, $font: 'museo-500') {

  body * {
    font-family: $font !important;
  }
}

html.blue-theme {
  $primary: #1a8cd2;
  $secondary: rgb(6, 42, 6);
  $font: 'museo-500';
  @include applyTheme($primary, $secondary, $font);
}

html.monarch-theme {
  $primary: #1a8cd2;
  $secondary: rgb(6, 42, 6);
  $font: 'museo-500';
  @include applyTheme($primary, $secondary);
}

html.fortune-theme {
  $primary: #005295;
  $secondary: #005295;
  $font: "CastleT";
  @include applyTheme($primary, $secondary, $font);
}