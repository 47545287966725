.search-modal {
    position: absolute;
    width: 100%;
    background: white;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    .autocomplete {
        position: relative;
        input {
            border:0px;
            border-bottom: 1px solid #ced4da;
            padding: 1.5rem 1rem 1.5rem 4rem !important;
            font-size: 16px;
            border-color: #ced4da !important;
            color: #333;
            &:focus {
                border-bottom: 2px solid #1a8cd2;
                border-color: #1a8cd2 !important;
            }
            &::placeholder {
                color: #333;
                opacity: 1; /* Firefox */
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #333;
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #333;
            }
        }
        .fa-arrow-left {
            position: absolute;
            left: 8px;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
            color: #333;
        }
        .fa-window-close {
            position: absolute;
            right: 8px;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .ui-menu {
        background: 0;
        border: 0;
        padding: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        position: absolute;
        left: 0;
        right: 0;
        overflow: auto;
        text-align: left;
        max-height: calc(100vh - 48px);
        overflow-y: auto;
        overflow-x: hidden;
        li {
            margin: 0;
            padding: 9px 20px 8px;
            display: block;
            color: #333;
        }
    }
}