header {
    display: flex !important;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #dddddd;
    height: 60px;
    width: 100%;
    img {
		width: 120px;
    }
    .menu-link {
        position: absolute;
        right: 16px;
        // > div:nth-child(2) {
        //     height: 16px !important;
        //     span:nth-child(2) {
        //         top: 8px !important
        //     }
        //     span:nth-child(3) {
        //         top: -8px !important
        //     }
        // }
    }
}