@import '../../variable.scss';


.my-plan {
    margin-bottom: 50px;
    .sticky-header {
        position: fixed;
        z-index: 100000;
        top: 0;
        width: 100%;
        left: 0;
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        header {
            background: #1a8cd2;
            height: 30px;
            border: 0px;
        }
        .sticky-tabs {
            background: white;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0px 24px;
            span {
                color: black;
                cursor: pointer;
                display: block;
                padding: 8px;
            }
            span.active {
                color: $blue;
                border-bottom: 2px solid $blue;
            }
        }
    }
    .plan-main-box {
        // height: calc(100% - 138px);
        // overflow: scroll;
        .plan-container {
            ul {
                list-style: none;
                border-left: 1px solid $orange;
                li {
                    padding-bottom: 16px;
                    font-size: 14px !important;
                    &::before {
                        content: "\2022";
                        color: $blue;
                        font-weight: bold;
                        display: inline-block;
                        width: 2em;
                        margin-left: -22px;
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

.time-line-ctnr {
    .time-line {
        text-align: left;
        .time-line-item {
            background: #dfe4e9 !important;
        }
        .time-label > span {
            background-color: $blue !important;
        }
    }
}
